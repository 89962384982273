<template>
  <div
      class="category-container p-6 bg-white flex items-center justify-between w-full"
  >
    <div class="flex items-center w-full">
      <div class="sort font-bold mr-6">{{ index + 1 }}</div>
      <el-image
          :src="info.header || require('../assets/image/cover.png')"
          class="cover mr-3"
          fit="cover"
      >
      </el-image>
      <div class="activity-info flex-1">
        <div
            v-if="showShareBtn == 'advisor' && info.have_student == true"
            @click.stop="showShare()"
            class="shareBtn"
        >
          is shared
        </div>
        <div
            v-if="showShareBtn == 'advisor' && info.have_student == false"
            @click.stop="showShare()"
            class="shareBtn"
        >
          Share
        </div>

        <div class="activity-name text-base font-bold mb-1">
          {{ info.name }}
        </div>
        <div class="activity-desc">
          <div
              class="desc text-sm content"
              :style="{ lineClamp: info.submission_ddl ? 2 : 4 }"
          >
            {{ info.intro }}
          </div>
        </div>
        <!-- <div
            v-if="info.submission_ddl"
            class="flex items-center justify-between"
            style="margin-top: 5px"
        >
          <div class="flex items-center">
            <div class="time">Enter by</div>
            <img src="../assets/image/icon_time.png" class="time-icon"/>
            <div class="time">
              {{ $dayjs(info.submission_ddl * 1000).format("YYYY/MM/DD") }}
            </div>
          </div>
          <p
              v-if="checkSubmissionEnded(info.submission_ddl)"
              class="common-tag"
              style="margin-left: 15px; color: #999; background-color: #ccc"
          >
            Closed
          </p>
          <p class="common-tag" v-else>On going</p>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Category",
  props: {
    info: Object,
    index: Number,
    showShareBtn: String,
  },
  mounted() {
  },
  methods: {
    handleView() {
      this.$emit("view");
    },
    checkSubmissionEnded(submission_ddl) {
      let todayStartTime = new Date(new Date().toLocaleDateString()).getTime()
      let startTime = new Date(todayStartTime - 1).getTime()
      if (startTime > (submission_ddl * 1000)) {
        return true;
      }
      return false;
    },

    showShare() {
      this.$emit("showShare");
    },
  },
};
</script>

<style lang="scss" scoped>
.category-container {
  .sort {
    margin-right: 15px;
    text-align: center;
    width: 34px;
    font-size: 16px;
    padding: 3px 0px;
    color: var(--c-primary);
    background-color: #ffefed;
    height: fit-content;
  }

  .cover {
    width: 96px;
    height: 96px;
  }

  .content {
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .shareBtn {
    position: absolute;

    padding: 5px 14px;
    box-sizing: border-box;
    background: #ffe2e2;
    border-radius: 6px 6px 6px 6px;
    font-size: 14px;
    font-family: Century Gothic-Bold, Century Gothic;
    color: #ff6450;
    right: 0px;
  }

  .activity-info {
    height: 96px;
    position: relative;

    .activity-name {
      color: #333333;
    }

    .time-icon {
      width: 18px;
      height: 18px;
      margin: 0 12px;
    }

    .time {
      font-size: 14px;
      font-weight: 400;
      color: #999999;
      line-height: 30px;
    }

    .activity-time {
      color: var(--c-primary);

      span {
        color: #999999;
      }

      p {
        margin-right: 45px;
      }
    }

    .activity-location {
      color: #ff8900;

      span {
        color: #999999;
      }

      p {
        margin-right: 60px;
      }
    }

    .activity-desc {
      color: #666666;
    }

    .desc {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
</style>
